import { FilterBase, FilterValueType } from '@/utils/widget/createSelections/interfaces';
export default {
  styles: {}
};
export const selectionConfig = {
  base: FilterBase.Series,
  valueType: FilterValueType.Numbers,
  seriesMinimumLength: 3,
  scoreResultGroupWeight: 1
};
export const labelsSelectionConfig = {
  base: FilterBase.Pairs
};
export const legendsSelectionConfig = {
  base: FilterBase.Pairs
};