import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { merge, camelCase } from 'lodash';
import { useMemo } from 'react';
import { ThemeId } from '@/interfaces/theme';
import { remCalc } from '@/config/design';
import { deleteUndefinedValues } from '@/utils/objects';
import { useTheme } from '@/components/widget/Layout/ThemeProvider';
const defaultStyles = Object.freeze({
  itemBorderWidth: remCalc(1)
});
export const getThemeDefaults = params => {
  return merge({}, defaultStyles, params.theme ? getStyleOverridesByTheme(params.theme, params.styles || {}) : {}, mapThemeStylesToSectionStyles(params.styles || {}));
};

const getStyleOverridesByTheme = (theme, styles) => {
  const shadcnDefaultStyles = Object.freeze({
    itemBorderColor: '#e4e4e7',
    itemBoxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
    itemBoxShadowSize: remCalc(3),
    // prevent shadow from being cut off
    itemPaddingVertical: remCalc(24),
    itemPaddingHorizontal: remCalc(24),
    itemBorderRadius: remCalc(12),
    itemLabelFontColor: styles['body-font-color'],
    itemLabelFontSize: remCalc(14),
    itemLabelLineHeight: remCalc(20),
    itemCaptionFontSize: remCalc(12),
    itemCaptionFontColor: styles['muted-font-color'],
    itemCaptionLineHeight: remCalc(16),
    itemCaptionFontWeight: '400',
    columnGap: remCalc(16),
    rowGap: remCalc(16)
  });
  return {
    [ThemeId.UniversalDesignLight]: {
      itemPaddingHorizontalUpMd: remCalc(24)
    },
    [ThemeId.UniversalDesignDark]: {
      itemPaddingHorizontalUpMd: remCalc(24)
    },
    [ThemeId.ShadcnLight]: shadcnDefaultStyles,
    [ThemeId.ShadcnDark]: shadcnDefaultStyles
  }[theme];
};

const mapThemeStylesToSectionStyles = styles => {
  // Convert the theme style keys to camelCase
  const themeStyles = Object.keys(styles).reduce((acc, key) => {
    acc[camelCase(key)] = styles[key];
    return acc;
  }, {});
  return deleteUndefinedValues(_objectSpread({}, themeStyles));
};

export const useOverviewStyles = () => {
  const themeCtx = useTheme();
  const defaultStyles = useMemo(() => getThemeDefaults({
    theme: themeCtx.theme.id,
    styles: themeCtx.styles
  }), [themeCtx]);
  return {
    defaultStyles,
    themeCtx
  };
};