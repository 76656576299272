import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { isValidNumber } from '@/utils/numbers';
import { getColorAtPalletIndex } from '@/utils/colors';
export const MIN_RANGE_LIMIT = 0;
export const MAX_RANGE_LIMIT = 100;
export const DEFAULT_TEMPLATE = '{{value | ssf 0%}}';
export const errorMessages = {
  outOfBounds: 'Range should be between 0 and 100',
  belowPrevious: 'Range should be greater than the previous range',
  aboveNext: 'Range should be less than the next range'
};
export const getDefaultRanges = () => {
  return [createRange({
    limit: 0,
    locked: true
  }), // Can't be removed
  createRange({
    limit: 33
  }), createRange({
    limit: 66
  }), createRange({
    limit: 100,
    locked: true
  }) // Can't be removed
  ];
}; // Create a range object
// that calculates a percentage between previous and next range

export const createRange = function () {
  let range = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let previousRange = arguments.length > 1 ? arguments[1] : undefined;
  let nextRange = arguments.length > 2 ? arguments[2] : undefined;
  if (isValidNumber(range === null || range === void 0 ? void 0 : range.limit)) return range;

  if (previousRange) {
    const previousRangeLimit = (previousRange === null || previousRange === void 0 ? void 0 : previousRange.limit) ?? MIN_RANGE_LIMIT;
    const nextRangeLimit = (nextRange === null || nextRange === void 0 ? void 0 : nextRange.limit) ?? MAX_RANGE_LIMIT;
    const limit = Math.round((nextRangeLimit + previousRangeLimit) / 2);
    return _objectSpread(_objectSpread({}, range), {}, {
      limit
    });
  }

  if (nextRange) {
    const nextRangeLimit = (nextRange === null || nextRange === void 0 ? void 0 : nextRange.limit) ?? MAX_RANGE_LIMIT;
    const limit = Math.round(nextRangeLimit / 2);
    return _objectSpread(_objectSpread({}, range), {}, {
      limit
    });
  }

  return _objectSpread(_objectSpread({}, range), {}, {
    limit: 0
  });
}; // Append a new range to the list

export const appendRange = (ranges, range) => {
  // If there are no ranges, create a new range with a limit of 0
  if (!ranges.length) {
    return [range];
  }

  return sortRanges([...ranges, range]);
}; // Filter out invalid ranges

export const filterInvalidRanges = ranges => {
  return ranges.filter((range, idx) => validateRange(range, ranges[idx - 1], ranges[idx + 1]).isValid);
}; // Sort ranges by limit

export const sortRanges = ranges => {
  return ranges.sort((a, b) => a.limit - b.limit);
}; // Check if the range is valid
// Range should be within the limits,
// greater than the previous range
// and less than the next range

export const validateRange = (range, previousRange, nextRange) => {
  // Check if the range is out of bounds
  const isWithinRange = isValidNumber(range.limit) && range.limit >= MIN_RANGE_LIMIT && range.limit <= MAX_RANGE_LIMIT; // Check if the range is greater than the previous range

  const isGreaterThanPrevious = !previousRange || range.limit > previousRange.limit; // Check if the range is less than the next range

  const isLessThanNext = !nextRange || range.limit < nextRange.limit; // Determine the error message

  let errorMessage = '';
  if (!isWithinRange) errorMessage = errorMessages.outOfBounds;else if (!isGreaterThanPrevious) errorMessage = errorMessages.belowPrevious;else if (!isLessThanNext) errorMessage = errorMessages.aboveNext;
  return {
    isWithinRange,
    isGreaterThanPrevious,
    isLessThanNext,
    isValid: isWithinRange && isGreaterThanPrevious && isLessThanNext,
    errorMessage
  };
};
export const getRangeColor = params => {
  const {
    range,
    rangeIndex,
    colorPallet
  } = params; // Offset the index by 1 to match the color pallet
  // as the first range is 0 and should not have a color

  return (range === null || range === void 0 ? void 0 : range.color) || getColorAtPalletIndex(rangeIndex - 1, colorPallet);
};
export const getDatasetsHash = datasets => datasets.map(dataset => [dataset.limit, dataset.color, dataset.label]).join(','); // Get the maximum width of the gauge based on the height
// in order to remove the horizontal scrollbar

const GAUGE_TYPE_HEIGHT_CORRECTION = {
  semicircle: 2.2,
  radial: 1.7,
  grafana: 1.7
};
export const getMaxGaugeWidth = (gaugeType, height) => {
  const heightCorrection = GAUGE_TYPE_HEIGHT_CORRECTION[gaugeType] ?? 1;
  return height * heightCorrection;
};