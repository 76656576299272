import { FilterBase, FilterValueType } from '@/utils/widget/createSelections/interfaces';
export default {
  styles: {}
};
export const selectionConfig = {
  base: FilterBase.Pairs,
  valueType: FilterValueType.Numbers,
  seriesMinimumLength: 1
};
export const labelsSelectionConfig = {
  base: FilterBase.Pairs
};
export const popular = [{
  colors: ['#65D783', '#E9BC4E', '#C03229'],
  name: 'Threat Level',
  category: ['popular']
}, {
  colors: ['#E65F7C', '#EFA8B9', '#FBE5EB', '#C0FCEE', '#8CFADF'],
  name: 'Get Positive',
  category: ['popular']
}, {
  colors: ['#97F080', '#D1D64F', '#EECC46', '#DC743D', '#D24D49'],
  name: 'Danger Zone',
  category: ['popular']
}, {
  colors: ['#62A3F4', '#73DDEE'],
  name: 'Cooling',
  category: ['popular']
}, {
  colors: ['#7483E0', '#9185DD', '#B088DA', '#CE8CD7', '#EF8FD4'],
  name: 'Rising Dawn',
  category: ['popular']
}, {
  colors: ['#E96A95', '#EC8D90', '#F0B28D', '#F5D78C', '#FBFD8C'],
  name: 'Sunburn',
  category: ['popular']
}];
export const monochromatic = [{
  colors: ['#6FD0B1', '#4AA380', '#3E8D6F', '#33765D', '#31725A'],
  name: 'Green',
  category: ['mono']
}, {
  colors: ['#F6CAC1', '#F09685', '#EA3E25', '#DC3A22', '#A42B17'],
  name: 'Red',
  category: ['mono']
}, {
  colors: ['#80A2F6', '#6790F5', '#4F7CF5', '#396AF3', '#2658F3'],
  name: 'Blue',
  category: ['mono']
}];
export const colorPallets = [...popular, ...monochromatic];
export const chartColorPallet = ['#828699', '#BABDCC', '#004DE5'];