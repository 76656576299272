import { useMemo } from 'react';
import flattenDeep from 'lodash/flattenDeep';
import createSelections from '@/utils/widget/createSelections';
export default function useSelectionLegends(_ref) {
  let {
    resultsUpdated,
    results,
    legendsSelections,
    legendsSelectionConfig
  } = _ref;
  const legendsSelectionsResult = useMemo(() => {
    if (!legendsSelections || !legendsSelectionConfig) return [];
    return flattenDeep(results.map(result => createSelections({
      result,
      selections: legendsSelections || '',
      selectionConfig: legendsSelectionConfig,
      labeled: false
    }))); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legendsSelections, legendsSelectionConfig, resultsUpdated]);
  const selectionLegends = useMemo(() => {
    return flattenDeep((legendsSelectionsResult || []).map(selections => selections.data.filter(selection => Boolean(selection.value)) // ignore empty values
    .map(selection => `${selection.value}`)));
  }, [legendsSelectionsResult]);
  return {
    selectionLegends,
    legendsSelectionsResult
  };
}