import templateEngine from '@/utils/templateEngine';
import { getDataLabelOptions } from '../internal/utils';
export const DEFAULT_DATA_LABEL_OPTIONS = {
  align: 'end',
  anchor: 'end'
}; // Create series data from group

export function createSeriesData(params) {
  const {
    selectionIndex,
    group,
    options,
    colorPallet,
    defaultColor = colorPallet[0],
    themeStyles,
    selectionLabels = [],
    getNextLabelIndex
  } = params;
  const userLabels = options.labels || {};
  const collapsible = Boolean(options.collapsible);
  const hideLegend = typeof options.omitFromLegend === 'boolean' ? options.omitFromLegend : false; // Provide default template

  const template = options.template || `{{value}}`; // Convert each item in group
  // into a renderable item for
  // the visualization

  return group.data // Filter out individual falsey items
  .filter(item => collapsible ? Boolean(item.value) : true).map(item => {
    let value = parseFloat(`${item.value}`);
    value = isNaN(value) ? 0 : value;
    const selectionLabel = typeof getNextLabelIndex === 'function' ? selectionLabels[getNextLabelIndex()] : '';
    const customLabel = userLabels[item.labelCell || ''] || options.label || selectionLabel || '';
    const color = options.pointBackgroundColor ?? defaultColor;
    const seriesData = {
      value,
      selection: group.selections,
      selectionIndex,
      hideLegend,
      name: customLabel || item.label || group.selections,
      template: templateEngine(template, {
        value: item.value
      }),
      pointBackgroundColor: color,
      pointStyle: options.pointStyle ?? 'circle',
      pointRadius: options.pointRadius ?? (themeStyles === null || themeStyles === void 0 ? void 0 : themeStyles.pointRadius),
      datalabels: getDataLabelOptions({
        value,
        backgroundColor: color,
        defaultColor: themeStyles === null || themeStyles === void 0 ? void 0 : themeStyles.chartDataLabelColor,
        options,
        defaults: DEFAULT_DATA_LABEL_OPTIONS
      })
    };
    return seriesData;
  });
} // Fill dataset with series data

export function fillDataset(data, dataset, labels) {
  const {
    value,
    selection,
    selectionIndex,
    name,
    template,
    pointBackgroundColor,
    pointStyle,
    pointRadius
  } = data;
  if (labels) labels.push(name);

  if (data.datalabels) {
    dataset.datalabels.push(data.datalabels);
  }

  dataset.data.push(value);
  dataset.templates.push(template);
  dataset.titles.push(name);
  dataset.selections.push(selection);
  dataset.selectionIndexes.push(selectionIndex);
  dataset.pointBackgroundColor.push(pointBackgroundColor);
  dataset.pointStyle.push(pointStyle);
  dataset.pointRadius.push(pointRadius);
}