import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { merge, camelCase, get, set } from 'lodash';
import { useMemo } from 'react';
import { ThemeId } from '@/interfaces/theme';
import design, { remToPx } from '@/config/design';
import deepClone from '@/utils/deepClone';
import { deleteNotTruthey, deleteUndefinedValues } from '@/utils/objects';
import { useTheme } from '@/components/widget/Layout/ThemeProvider';
import { SMOOTH_TENSION, SHARP_TENSION } from '../../../utils/tension';
const defaultStyles = Object.freeze({
  tension: SHARP_TENSION,
  lineWidth: 2,
  lineDash: [0, 0],
  xAxisGridVisible: true,
  yAxisGridVisible: true,
  xAxisGridBorderWidth: 1,
  yAxisGridBorderWidth: 1,
  xAxisGridBorderDash: [5, 5],
  yAxisGridBorderDash: [5, 5],
  xAxisTicksPadding: 16,
  yAxisTicksPadding: 16,
  chartAxisFontColor: design.colors.gray60,
  pointRadius: 3,
  chartBackgroundColor: design.colors.white,
  xAxisGridColor: design.colors.gray20,
  yAxisGridColor: design.colors.gray20,
  chartDataLabelBorderRadius: 4,
  chartDataLabelBackgroundColor: design.colors.gray10,
  chartDataLabelColor: design.colors.gray100,
  areaChartDefaultFill: 'fade',
  chartGradientStart: 0.27,
  chartGradientStartOpacity: 0.1,
  chartGradientStop: 0,
  chartGradientStopOpacity: 0,
  chartGradientTransparency: 0.4
});
const shadcnDefaultStyles = {
  tension: SMOOTH_TENSION,
  xAxisGridVisible: false,
  xAxisGridBorderDash: [0, 0],
  yAxisGridBorderDash: [0, 0],
  pointRadius: 0,
  areaChartDefaultFill: 'transparent'
};
const styleOverridesByTheme = Object.freeze({
  [ThemeId.UniversalDesignDark]: {
    chartGradientStart: 1,
    chartGradientStartOpacity: 0.58
  },
  [ThemeId.ShadcnLight]: shadcnDefaultStyles,
  [ThemeId.ShadcnDark]: shadcnDefaultStyles
});
export const getThemeDefaults = params => {
  return merge({}, defaultStyles, params.theme ? styleOverridesByTheme[params.theme] : {}, mapThemeStylesToLineStyles(params.styles || {}, params.config));
};
export const mapConfigToLineStyles = config => {
  var _config$globalSeriesO, _config$globalSeriesO2, _config$globalSeriesO3, _config$globalSeriesO4, _config$globalSeriesO5, _config$globalSeriesO6;

  return _objectSpread(_objectSpread({}, deleteNotTruthey({
    chartBackgroundColor: config.chartBackground,
    areaChartDefaultFill: config.areaChartDefaultFill,
    xAxisGridColor: config.xAxisGridColor,
    yAxisGridColor: config.yAxisGridColor,
    xAxisGridBorderDash: config.xAxisGridBorderDash,
    yAxisGridBorderDash: config.yAxisGridBorderDash,
    lineDash: (_config$globalSeriesO = config.globalSeriesOptions) === null || _config$globalSeriesO === void 0 ? void 0 : _config$globalSeriesO.lineDash,
    lineWidth: (_config$globalSeriesO2 = config.globalSeriesOptions) === null || _config$globalSeriesO2 === void 0 ? void 0 : _config$globalSeriesO2.lineWidth,
    lineColor: (_config$globalSeriesO3 = config.globalSeriesOptions) === null || _config$globalSeriesO3 === void 0 ? void 0 : _config$globalSeriesO3.lineColor,
    lineBackgroundColor: (_config$globalSeriesO4 = config.globalSeriesOptions) === null || _config$globalSeriesO4 === void 0 ? void 0 : _config$globalSeriesO4.lineBackgroundColor
  })), deleteUndefinedValues({
    pointRadius: (_config$globalSeriesO5 = config.globalSeriesOptions) === null || _config$globalSeriesO5 === void 0 ? void 0 : _config$globalSeriesO5.pointRadius,
    xAxisGridBorderWidth: config.xAxisGridBorderWidth,
    yAxisGridBorderWidth: config.yAxisGridBorderWidth,
    tension: (_config$globalSeriesO6 = config.globalSeriesOptions) === null || _config$globalSeriesO6 === void 0 ? void 0 : _config$globalSeriesO6.tension,
    xAxisGridVisible: config.xAxisGridVisible,
    yAxisGridVisible: config.yAxisGridVisible
  }));
}; // Reset the styles that are not part of the default styles
// Note: keep in sync with "mapConfigToLineStyles"

export const resetConfigStyles = config => {
  let hasChanges = false;
  const localConfig = deepClone(config);
  const fieldsToReset = ['chartBackground', 'areaChartDefaultFill', 'xAxisGridColor', 'yAxisGridColor', 'xAxisGridBorderDash', 'yAxisGridBorderDash', 'xAxisGridBorderWidth', 'yAxisGridBorderWidth', 'xAxisGridVisible', 'yAxisGridVisible', 'globalSeriesOptions.tension', 'globalSeriesOptions.pointRadius', 'globalSeriesOptions.lineDash', 'globalSeriesOptions.lineWidth', 'globalSeriesOptions.lineColor', 'globalSeriesOptions.lineBackgroundColor'];
  const optionFieldsToReset = ['lineColor', 'lineDash', 'lineWidth', 'lineBackgroundColor', 'pointRadius', 'pointStyle', 'pointBackgroundColor'];
  const changes = {};
  fieldsToReset.forEach(field => {
    const value = get(localConfig, field);
    if (!value) return;
    hasChanges = true;
    set(changes, field, null);
  });

  if (localConfig.seriesOptions) {
    localConfig.seriesOptions.forEach((option, idx) => {
      optionFieldsToReset.forEach(field => {
        const value = get(option, field);
        if (!value) return;
        hasChanges = true;
        set(changes, `seriesOptions.${idx}.${field}`, null);
      });
    });
  }

  if (localConfig.collectionOptions) {
    localConfig.collectionOptions.forEach((option, idx) => {
      optionFieldsToReset.forEach(field => {
        const value = get(option, field);
        if (!value) return;
        hasChanges = true;
        set(changes, `collectionOptions.${idx}.${field}`, null);
      });
    });
  }

  return {
    hasChanges,
    changes
  };
};

const mapThemeStylesToLineStyles = (styles, config) => {
  // Convert the theme style keys to camelCase
  const themeStyles = Object.keys(styles).reduce((acc, key) => {
    acc[camelCase(key)] = styles[key];
    return acc;
  }, {});
  const bodyFontSize = remToPx(themeStyles.bodyFontSize);
  const chartAxisFontSize = remToPx(themeStyles.chartAxisFontSize);
  return deleteUndefinedValues(_objectSpread(_objectSpread({}, themeStyles), {}, {
    chartBackgroundColor: styles['body-background-color'],
    xAxisGridColor: themeStyles.chartGridColor,
    yAxisGridColor: themeStyles.chartGridColor,
    chartAxisFontSize: !isNaN(chartAxisFontSize) ? chartAxisFontSize : undefined,
    chartDataLabelBackgroundColor: themeStyles.secondaryColor,
    chartDataLabelColor: themeStyles.bodyFontColor,
    chartDataLabelBorderRadius: themeStyles.radiusBase,
    legendFontFamily: themeStyles.bodyFontFamily,
    legendFontSize: !isNaN(bodyFontSize) ? bodyFontSize : undefined,
    legendLineHeight: themeStyles.bodyLineHeight,
    legendColor: themeStyles.bodyFontColor,
    titleFontFamily: themeStyles.bodyFontFamily,
    titleFontSize: !isNaN(bodyFontSize) ? bodyFontSize : undefined,
    titleLineHeight: themeStyles.bodyLineHeight,
    titleColor: themeStyles.mutedFontColor,
    lineWidth: isAreaChart(config) ? themeStyles.chartAreaLineWidth : themeStyles.chartLineWidth
  }));
};

export const isAreaChart = config => config.chartStyle && ['area', 'stackedArea'].includes(config.chartStyle);
export const useLineStyles = config => {
  const themeCtx = useTheme();
  const defaultStyles = useMemo(() => getThemeDefaults({
    theme: themeCtx.theme.id,
    styles: themeCtx.styles,
    config
  }), [themeCtx, config]); // The merge helper mutates the first object,
  // so we use an empty object to keep the default styles safe.
  // Merge styles in order: Default -> Defaults by Theme -> Theme Styles -> Config

  const lineThemeStyles = useMemo(() => merge({}, defaultStyles, mapConfigToLineStyles(config)), [config, defaultStyles]);
  return {
    lineThemeStyles,
    defaultStyles,
    themeCtx
  };
};