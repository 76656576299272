import { marked } from 'marked';
import templateEngine from '@/utils/templateEngine'; // Create columns from a single selection

export function createColumns(srcResults, selectionsLabeled) {
  let labels = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  // Tabular only supports single selection
  const results = srcResults[0] || [];
  const columns = results.length;
  return Array(columns).fill(0).map((_, colIndex) => {
    const col = results[colIndex] || {};
    return {
      Header: selectionsLabeled ? ((col.data || [])[0] || {}).label || '' : labels[colIndex] || '',
      accessor: `col${colIndex}`,
      // accessor is the "key" in the data
      selection: col.selections || '',
      selectionIndex: col.selectionIndex || 0
    };
  });
} // Create table data from a single selection

export function createData(srcResults, seriesOptions, globalSeriesOptions) {
  // Tabular only supports single-collection
  // with a single selections
  const results = srcResults[0] || [];
  const columns = results.length; // Assume 1st series option belongs to 1st selection
  // NOTE: only applicable to tabluar selection strategy

  const tableOptions = seriesOptions[0] || {}; // Discover selection with most rows

  const rows = Math.max(0, results.reduce((acc, _ref) => {
    let {
      data
    } = _ref;
    return data.length > acc ? data.length : acc;
  }, 0));
  return Array(rows).fill(0).map((_, rowIndex) => {
    return Array(columns).fill(0).reduce((acc, _, colIndex) => {
      const options = tableOptions;
      const columnData = (results[colIndex] || {}).data || [];
      const srcValue = (columnData[rowIndex] || {}).value;
      const value = srcValue !== undefined ? srcValue : '';
      const template = options.template || (globalSeriesOptions === null || globalSeriesOptions === void 0 ? void 0 : globalSeriesOptions.template) || '{{value}}'; // Interpolate the template

      acc[`col${colIndex}`] = marked.parseInline(templateEngine(template, {
        value
      }));
      acc[`colSelection${colIndex}`] = (results[colIndex] || {}).selections || '';
      acc[`colSelectionIndex${colIndex}`] = (results[colIndex] || {}).selectionIndex || 0;
      return acc;
    }, {});
  });
}