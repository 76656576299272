import { _remCalc } from '@/config/design';
import { FilterBase, FilterValueType } from '@/utils/widget/createSelections/interfaces';
export default {
  styles: {
    minChartHeight: _remCalc(191)
  }
};
export const selectionConfig = {
  valueType: FilterValueType.Numbers
};
export const labelsSelectionConfig = {
  base: FilterBase.Pairs
};