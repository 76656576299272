import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { getGradientObject } from '@/utils/gradient';
import { transparentize } from '@/utils/colors';
export const DEFAULT_DATA_LABEL_OPTIONS = {
  align: 'end',
  anchor: 'end'
}; // Get line chart fill gradient

export const getFillGradient = (context, chartArea, color, fallbackColor, areaFillStrategy, themeStyles) => {
  const gradientObject = getGradientObject(color || ''); // Return transparent for regular line charts

  if (!areaFillStrategy && !color) return 'transparent'; // Return the color if it's not a gradient

  if (!gradientObject.isGradient && !areaFillStrategy) return color; // Fill the area with a solid color

  if (!gradientObject.isGradient && areaFillStrategy === 'solid') {
    return color || fallbackColor;
  } // Fill the area with a transparent and partially see-through color


  if (!gradientObject.isGradient && areaFillStrategy === 'transparent') {
    return transparentize(color || fallbackColor, (themeStyles === null || themeStyles === void 0 ? void 0 : themeStyles.chartGradientTransparency) ?? 0.4);
  } // Return the fade gradient if no color is provided


  if (!color && fallbackColor && areaFillStrategy === 'fade') {
    const gradient = context.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop((themeStyles === null || themeStyles === void 0 ? void 0 : themeStyles.chartGradientStart) ?? 1, transparentize(fallbackColor, (themeStyles === null || themeStyles === void 0 ? void 0 : themeStyles.chartGradientStartOpacity) ?? 1));
    gradient.addColorStop((themeStyles === null || themeStyles === void 0 ? void 0 : themeStyles.chartGradientStop) ?? 0, transparentize(fallbackColor, (themeStyles === null || themeStyles === void 0 ? void 0 : themeStyles.chartGradientStopOpacity) ?? 0));
    return gradient;
  }

  if (!color) return 'transparent'; // Create the radial gradient

  if (gradientObject.gradientType === 'radial-gradient') {
    const centerX = chartArea.left + chartArea.width / 2;
    const centerY = chartArea.top + chartArea.height / 2; // Determine the maximum radius (distance from center to corner)

    const maxRadius = Math.max(chartArea.width, chartArea.height) / 2; // Create a radial gradient from the center outward

    const gradient = context.createRadialGradient(centerX, centerY, 0, // Start circle (center, radius 0)
    centerX, centerY, maxRadius // End circle (center, max radius)
    );
    gradientObject.colors.forEach(color => {
      gradient.addColorStop((color.left ?? 0) / 100, color.value);
    });
    return gradient;
  } // Create the linear gradient


  const {
    x0,
    y0,
    x1,
    y1
  } = calculateLinearGradientPoints(chartArea, gradientObject.degrees ?? 180 // fallback to 180 degrees
  );
  const gradient = context.createLinearGradient(x0, y0, x1, y1);
  gradientObject.colors.forEach(color => {
    gradient.addColorStop((color.left ?? 0) / 100, color.value);
  });
  return gradient;
};

function calculateLinearGradientPoints(chartArea, degrees) {
  // Calculate the center point of the chart area
  const centerX = chartArea.left + chartArea.width / 2;
  const centerY = chartArea.top + chartArea.height / 2; // Adjust the angle to match the Canvas coordinate system

  const angleRad = (degrees - 90) * Math.PI / 180; // Calculate the length of the gradient line (half of the diagonal length)

  const halfDiagonal = Math.sqrt(chartArea.width ** 2 + chartArea.height ** 2) / 2; // Calculate the delta values based on the angle

  const deltaX = halfDiagonal * Math.cos(angleRad);
  const deltaY = halfDiagonal * Math.sin(angleRad); // Define start and end points of the gradient line

  const x0 = centerX - deltaX;
  const y0 = centerY - deltaY;
  const x1 = centerX + deltaX;
  const y1 = centerY + deltaY;
  return {
    x0,
    y0,
    x1,
    y1
  };
} // Fill dataset with series data


export function fillDataset(data, dataset, labels) {
  const {
    value,
    selection,
    selectionIndex,
    name,
    template,
    pointBackgroundColor,
    pointStyle,
    pointRadius
  } = data;
  if (labels) labels.push(name);

  if (data.datalabels) {
    dataset.datalabels.push(data.datalabels);
  }

  dataset.data.push(value);
  dataset.templates.push(template);
  dataset.titles.push(name);
  dataset.selections.push(selection);
  dataset.selectionIndexes.push(selectionIndex);
  dataset.pointBackgroundColor.push(pointBackgroundColor);
  dataset.pointStyle.push(pointStyle);
  dataset.pointRadius.push(pointRadius);
} // Util to add background and border color to dataset

export function addColorsToDataset(config) {
  const {
    dataset,
    borderColor,
    backgroundColor,
    fill,
    areaChartDefaultFill,
    themeStyles
  } = config;
  dataset.fill = fill || 'start';

  dataset.backgroundColor = context => {
    const chart = context.chart;
    const {
      ctx,
      chartArea
    } = chart;

    if (!chartArea) {
      // This case happens on initial chart load
      return;
    }

    return getFillGradient(ctx, chartArea, backgroundColor, borderColor, areaChartDefaultFill, themeStyles);
  };

  dataset.borderColor.push(borderColor);
} // Flattens the data from the datasets into a string

export function dataToString(datasets) {
  return joinNewLine(datasets.map(dataset => joinNewLine(dataset.data.map((item, idx) => `${dataset.titles[idx]} => ${item}`))));
} // Converts the data from the datasets into a string array

export function dataToStringArr(datasets) {
  return datasets.map(dataset => joinNewLine(dataset.data.map((item, idx) => `${dataset.titles[idx]} => ${item}`)));
}
export function joinNewLine(arr) {
  return arr.join('\n');
} // Temporary overrides for line chart selections

export function overrideConfig(config) {
  return _objectSpread(_objectSpread({}, config), {}, {
    selectionsLabeled: false,
    selectionsStrategy: 'multiLine'
  });
}