import { useMemo } from 'react';
import { createData as createPairedDatasets } from './pairsToResults';
import { createData as createStackedDatasets } from './stackedToResults';
import { createData as createCategorizedDatasets } from './categoriesToResults';
export default function useData(_ref) {
  let {
    results,
    seriesOptions,
    selectionLabels,
    selectionLegends,
    collectionOptions = [],
    globalSeriesOptions = {},
    columnLabels,
    legends,
    colorPallet,
    selectionsStrategy,
    hasLegend = true
  } = _ref;
  const chartData = useMemo(() => {
    if (!results || !results.length) {
      return {
        labels: [],
        datasets: []
      };
    } // Lookup the createData function based on the selectionsStrategy


    const createDataFunctions = {
      stacked: createStackedDatasets,
      categories: createCategorizedDatasets,
      default: createPairedDatasets
    };
    const createData = createDataFunctions[selectionsStrategy || 'default'];
    return createData({
      results,
      seriesOptions,
      colorPallet,
      collectionOptions,
      columnLabels,
      selectionLabels,
      selectionLegends,
      legends,
      hasLegend,
      globalSeriesOptions
    });
  }, [columnLabels, legends, results, seriesOptions, colorPallet, collectionOptions, selectionsStrategy, globalSeriesOptions, selectionLabels, selectionLegends, hasLegend]);
  return {
    chartData
  };
}