import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import merge from 'lodash/merge';
import flatten from 'lodash/flatten';
import { createTrendlineMap } from '../../../utils/trendlines';
import { filterCollapsedGroups, getDataBoundaries } from '../../internal/utils';
import { fillDataset, appendTrendlineDataset } from '../../internal/BarChart/utils';
import { createSeriesData } from './utils';
// Create data for selections v2
export function createData(_ref) {
  let {
    results,
    seriesOptions,
    selectionLabels = [],
    selectionLegends = [],
    globalSeriesOptions = {},
    collectionOptions = [],
    colorPallet,
    columnLabels = [],
    legends = [],
    globalTrendline
  } = _ref;
  const trendlines = createTrendlineMap(); // Process selections and create series data

  const {
    groupedSelections,
    valueAxisTemplate
  } = processSelections({
    results,
    seriesOptions,
    globalSeriesOptions,
    collectionOptions,
    trendlines,
    globalTrendline,
    colorPallet
  }); // Create datasets from grouped selections

  const datasets = createDatasets(groupedSelections, legends, selectionLegends); // Get max series count

  const maxSeries = Math.max(...datasets.map(dataset => dataset.data.length)); // Calculate min/max of all datasets

  const dataBoundaries = getDataBoundaries(datasets); // Append optional trendline datasets

  const trendlinesCount = appendTrendlineDataset(datasets, trendlines, dataBoundaries); // Create final labels, limit to max series

  const labels = maxSeries > 0 ? new Array(maxSeries).fill('').map((_, idx) => columnLabels[idx] || selectionLabels[idx] || `Item ${idx + 1}`) : [];
  return {
    legendsCount: datasets.length - trendlinesCount,
    labels,
    datasets,
    dataBoundaries,
    valueAxisTemplate
  };
}
export function processSelections(_ref2) {
  let {
    results,
    seriesOptions,
    globalSeriesOptions,
    collectionOptions,
    trendlines,
    globalTrendline,
    colorPallet
  } = _ref2;
  const groupedSelections = {};
  let valueAxisTemplate = '';
  flatten(results).forEach(selectionGroup => {
    const {
      selectionIndex,
      collectionIndex
    } = selectionGroup;
    const options = merge({}, globalSeriesOptions, seriesOptions[selectionIndex] || {}); // Use the first template as the value axis template

    if (options.template && !valueAxisTemplate) {
      valueAxisTemplate = options.template;
    } // Skip if group is collapsed


    if (!filterCollapsedGroups(selectionGroup, options)) {
      return;
    }

    const collectionOption = collectionOptions[collectionIndex] || {}; // Use the first template as the value axis template

    if (collectionOption.template && !valueAxisTemplate) {
      valueAxisTemplate = collectionOption.template;
    }

    processGroupData(selectionGroup, {
      options,
      collectionOption,
      selectionIndex,
      collectionIndex,
      colorPallet,
      groupedSelections,
      trendlines,
      globalTrendline
    });
  });
  return {
    groupedSelections,
    valueAxisTemplate
  };
} // Processes raw selection results into grouped selections

function processGroupData(selectionGroup, context) {
  const {
    options,
    collectionOption,
    selectionIndex,
    collectionIndex,
    colorPallet,
    groupedSelections,
    trendlines,
    globalTrendline
  } = context;
  selectionGroup.data // Filter out empty items if collapsible
  .filter(item => options.collapsible ? Boolean(item.value) : true).forEach((item, index) => {
    if (!groupedSelections[index]) {
      groupedSelections[index] = [];
    }

    const [seriesData] = createSeriesData({
      group: _objectSpread(_objectSpread({}, selectionGroup), {}, {
        data: [item]
      }),
      options,
      collectionOption,
      selectionIndex,
      collectionIndex,
      trendlines,
      globalTrendline,
      colorPallet
    });
    if (!seriesData) return;
    groupedSelections[index].push({
      group: _objectSpread(_objectSpread({}, selectionGroup), {}, {
        data: [item]
      }),
      options,
      collectionOption,
      seriesData
    });
  });
} // Creates chart datasets from grouped selections


function createDatasets(groupedSelections, legends, selectionLegends) {
  const datasets = [];
  Object.values(groupedSelections).forEach(groupSelections => {
    groupSelections.forEach((_ref3, datasetIdx) => {
      let {
        seriesData
      } = _ref3;
      const legend = legends[datasetIdx] || selectionLegends[datasetIdx] || `Legend ${datasetIdx + 1}`;
      seriesData.name = legend;

      if (!datasets[datasetIdx]) {
        datasets[datasetIdx] = createEmptyDataset(legend);
      }

      fillDataset(seriesData, datasets[datasetIdx]);
    });
  });
  return datasets;
}

function createEmptyDataset(legend) {
  return {
    data: [],
    backgroundColor: [],
    templates: [],
    titles: [],
    selections: [],
    selectionIndexes: [],
    collectionIndex: -1,
    datalabels: [],
    borderColor: [],
    borderWidth: [],
    label: legend
  };
}