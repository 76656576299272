import flattenDeep from 'lodash/flattenDeep';
import { useMemo } from 'react';
import templateEngine from '@/utils/templateEngine';
import { isValidNumber } from '@/utils/numbers';
import { getDefaultRanges, getRangeColor, MAX_RANGE_LIMIT, MIN_RANGE_LIMIT } from './utils';
const defaultChartData = Object.freeze({
  value: 0,
  datasets: [],
  ticks: []
});
export default function useData(_ref) {
  let {
    results,
    colorPallet,
    globalSeriesOptions = {},
    selectionLabels,
    ranges = getDefaultRanges()
  } = _ref;
  const chartData = useMemo(() => {
    var _datasets;

    const flattenedResults = flattenDeep(results);
    const [resultItem] = flattenedResults;
    const dataItem = ((resultItem === null || resultItem === void 0 ? void 0 : resultItem.data) || [])[0];
    const selectedValue = dataItem === null || dataItem === void 0 ? void 0 : dataItem.value;
    const selectedLabel = (selectionLabels || [])[0] || (dataItem === null || dataItem === void 0 ? void 0 : dataItem.label);
    const selectionIndex = resultItem === null || resultItem === void 0 ? void 0 : resultItem.selectionIndex;
    const selection = resultItem === null || resultItem === void 0 ? void 0 : resultItem.selections;

    if (!isValidNumber(selectedValue)) {
      return defaultChartData;
    } // Expect the selected value to be between 0 and 1
    // Multiply by 100 to get a percentage


    const value = Math.min(Math.max(selectedValue * 100, MIN_RANGE_LIMIT), MAX_RANGE_LIMIT); // Create datasets based on the ranges

    const datasets = ranges.map((range, rangeIndex) => {
      return {
        limit: range.limit,
        color: getRangeColor({
          range,
          rangeIndex,
          colorPallet
        }),
        label: range.label || ''
      };
    }); // Append a new range if the last range is less than the maximum

    if (((_datasets = datasets[datasets.length - 1]) === null || _datasets === void 0 ? void 0 : _datasets.limit) < MAX_RANGE_LIMIT) {
      datasets.push({
        limit: MAX_RANGE_LIMIT,
        color: getRangeColor({
          range: null,
          rangeIndex: datasets.length - 1,
          colorPallet
        })
      });
    }

    return {
      value: value,
      valueTemplate: globalSeriesOptions.template ? templateEngine(globalSeriesOptions.template, {
        value: selectedValue
      }) : undefined,
      label: globalSeriesOptions.labelsTemplate && selectedLabel ? templateEngine(globalSeriesOptions.labelsTemplate, {
        value: selectedLabel
      }) : selectedLabel,
      ticks: datasets.map(dataset => ({
        value: dataset.limit,
        label: dataset.label || ''
      })),
      datasets: datasets,
      selection: selection,
      selectionIndex: selectionIndex
    };
  }, [results, globalSeriesOptions, colorPallet, ranges, selectionLabels]);
  return {
    chartData
  };
}