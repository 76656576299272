import flattenDeep from 'lodash/flattenDeep';
import merge from 'lodash/merge';
import { getColorAtPalletIndex } from '@/utils/colors';
import templateEngine from '@/utils/templateEngine';
import { filterCollapsedGroups, getDataBoundaries } from '../../internal/utils';
import { getDataLabelOptions } from '../../internal/utils';
import { DEFAULT_DATA_LABEL_OPTIONS } from '../../internal/BarChart/utils';
export function createData(_ref) {
  let {
    results,
    seriesOptions,
    colorPallet,
    columnLabels = [],
    globalSeriesOptions = {},
    legends = []
  } = _ref;
  let maxSeriesCount = 0;
  const flattenResults = flattenDeep(results);
  const legendsCount = flattenResults.length;
  let valueAxisTemplate = '';
  const datasets = flattenResults.map(selectionResultGroup => {
    const {
      selectionIndex
    } = selectionResultGroup;
    const options = merge({}, globalSeriesOptions, seriesOptions[selectionIndex] || {}); // Use the first template found
    // as the template for the value axis

    if (options.template && !valueAxisTemplate) {
      valueAxisTemplate = options.template;
    }

    return {
      group: selectionResultGroup,
      options,
      selectionIndex
    };
  }) // Filter collapsed
  .filter(_ref2 => {
    let {
      group,
      options
    } = _ref2;
    return filterCollapsedGroups(group, options);
  }) // Create series data
  .map((_ref3, groupIdx) => {
    var _group$data$find;

    let {
      group,
      options,
      selectionIndex
    } = _ref3;
    // Provide default template
    const template = options.template || `{{value}}`; // Lookup group label

    const datasetLabel = (options === null || options === void 0 ? void 0 : options.label) || ((_group$data$find = group.data.find(item => Boolean(item.label))) === null || _group$data$find === void 0 ? void 0 : _group$data$find.label) || `Category ${selectionIndex + 1}`; // Lookup legend

    const legend = legends[groupIdx] || datasetLabel;
    const dataset = {
      data: [],
      collectionIndex: -1,
      backgroundColor: [],
      templates: [],
      titles: [],
      selections: [],
      selectionIndexes: [],
      datalabels: [],
      borderColor: [],
      borderWidth: [],
      label: legend
    }; // Track the max series count

    maxSeriesCount = Math.max(maxSeriesCount, group.data.length); // Convert each item in group
    // into a renderable item for
    // the visualization

    group.data // Filter out individual falsey items
    .filter(item => options.collapsible ? Boolean(item.value) : true).forEach(item => {
      let value = parseFloat(`${item.value}`);
      value = isNaN(value) ? 0 : value;
      const color = options.color || getColorAtPalletIndex(selectionIndex, colorPallet);
      dataset.data.push(value);
      dataset.selections.push(group.selections);
      dataset.selectionIndexes.push(selectionIndex);
      dataset.backgroundColor.push(color);
      dataset.templates.push(templateEngine(template, {
        value: item.value
      }));
      dataset.titles.push(datasetLabel);
      dataset.borderColor.push(options.barBorderColor ?? color);
      dataset.borderWidth.push(options.barBorderWidth ?? 0);
      dataset.datalabels.push(getDataLabelOptions({
        value,
        backgroundColor: color,
        options,
        defaults: DEFAULT_DATA_LABEL_OPTIONS
      }));
    });
    return dataset;
  }); // Fill labels with empty strings based on the max series count
  // to display the correct number of columns

  const labels = Array.from({
    length: maxSeriesCount
  }).map((_, index) => columnLabels[index] || ''); // Calculate min/max of all datasets

  const dataBoundaries = getDataBoundaries(datasets, true);
  return {
    legendsCount: legendsCount,
    labels: labels,
    datasets: datasets,
    dataBoundaries: dataBoundaries,
    valueAxisTemplate: valueAxisTemplate
  };
}