import { useMemo } from 'react';
import { createData as createSetDatasets } from './setToResults';
import { createData as createMultiLineDatasets } from './multiLinesToResults';
export default function useData(_ref) {
  let {
    results,
    selectionLabels,
    selectionLegends,
    seriesOptions,
    globalSeriesOptions = {},
    collectionOptions = [],
    colorPallet,
    selectionsStrategy = 'default',
    chartType = 'horizontal',
    legends,
    areaChartDefaultFill,
    selectionsLabeled,
    themeStyles
  } = _ref;
  const chartData = useMemo(() => {
    if (!results || !results.length) {
      return {
        labels: [],
        datasets: []
      };
    } // Lookup the createData function based on the selectionsStrategy


    const createDataFunctions = {
      default: createSetDatasets,
      multiLine: createMultiLineDatasets
    };
    const createData = createDataFunctions[selectionsStrategy || 'default'];
    return createData({
      results,
      selectionLabels,
      selectionLegends,
      globalSeriesOptions,
      seriesOptions,
      colorPallet,
      collectionOptions,
      chartType,
      legends,
      areaChartDefaultFill,
      selectionsLabeled,
      themeStyles
    });
  }, [selectionsStrategy, results, selectionLabels, selectionLegends, collectionOptions, globalSeriesOptions, seriesOptions, colorPallet, chartType, legends, selectionsLabeled, areaChartDefaultFill, themeStyles]);
  return {
    chartData
  };
}