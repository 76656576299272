import { useMemo } from 'react';
import flattenDeep from 'lodash/flattenDeep';
import templateEngine from '@/utils/templateEngine';
import createSelections from '@/utils/widget/createSelections';
export default function useSelectionLabels(_ref) {
  let {
    resultsUpdated,
    results,
    selectionsLabeled,
    labelsTemplate,
    labelsSelections,
    labelsSelectionConfig
  } = _ref;
  const labelsSelectionsResult = useMemo(() => {
    if (!labelsSelections || !labelsSelectionConfig) return [];
    return flattenDeep(results.map(result => createSelections({
      result,
      selections: labelsSelections || '',
      selectionConfig: labelsSelectionConfig,
      labeled: false
    }))); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelsSelections, labelsSelectionConfig, resultsUpdated]);
  const selectionLabels = useMemo(() => {
    if (selectionsLabeled) return [];
    return flattenDeep((labelsSelectionsResult || []).map(selections => selections.data.filter(selection => Boolean(selection.value)) // ignore empty values
    .map(selection => {
      // Provide default template
      const template = labelsTemplate || `{{value}}`;
      return templateEngine(template, {
        value: `${selection.value}`
      });
    })));
  }, [labelsSelectionsResult, labelsTemplate, selectionsLabeled]);
  return {
    selectionLabels,
    labelsSelectionsResult
  };
}