import { useCallback } from 'react';
import { getRelativePosition } from 'chart.js/helpers';
import { getClickPositionFromEvent } from '@/utils/dom';
import { selectEditingArea } from '@/components/Editor/hooks/useEditingAreas';
export default function useLineEditingArea(_ref) {
  let {
    widgetId,
    sectionId,
    chartData
  } = _ref;
  // Handles click events on a chart to identify and set the right editing areas.
  // There are three scenarios:
  // 1. Click on a chart point directly
  // 2. Click is near a dataset line
  // 3. Click is in empty chart area
  const handleChartClick = useCallback(function (event, activeElements, chart) {
    let detectAreaClick = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
    // Distance in pixels to consider a click "near" a line
    const PROXIMITY_THRESHOLD_PX = 20;
    const editingContext = {
      widgetId,
      sectionId,
      chartData
    }; // If empty or only one dataset, treat as empty area click

    if (chart.data.datasets.length <= 1) {
      handleEmptyAreaClick(editingContext, event);
      return;
    } // Direct click on point


    if ((activeElements === null || activeElements === void 0 ? void 0 : activeElements.length) > 0) {
      handleDirectElementClick(editingContext, event, activeElements);
      return;
    } // Find nearest dataset


    const nearestLine = findNearestDataset(event, chart); // Ensure we found a valid dataset

    if (nearestLine.datasetIndex === null) {
      handleEmptyAreaClick(editingContext, event);
      return;
    }

    const dataset = chart.data.datasets[nearestLine.datasetIndex]; // @ts-expect-error - background fill is not guaranteed to be defined

    const hasBackgroundFill = dataset.hasBackgroundFill; // Click near a dataset line

    const isClickWithinArea = (detectAreaClick || hasBackgroundFill) && !nearestLine.isAboveLine;
    const shouldSelectLine = isClickWithinArea || isClickNearLine(nearestLine, PROXIMITY_THRESHOLD_PX);

    if (shouldSelectLine) {
      // @ts-expect-error - dataset is not guaranteed to be defined
      const collectionIndex = (dataset === null || dataset === void 0 ? void 0 : dataset.collectionIndex) ?? 0;
      handleNearLineClick(editingContext, collectionIndex, event);
      return;
    } // Click in empty chart area


    handleEmptyAreaClick(editingContext, event);
  }, [widgetId, sectionId, chartData]);
  return {
    handleChartClick
  };
} // Determines if a click is close enough to a line to be considered intentional.

const isClickNearLine = (nearestLine, threshold) => {
  return nearestLine.distance < threshold && nearestLine.datasetIndex !== null;
}; // Handles clicks that are near a dataset line.


const handleNearLineClick = (context, datasetIndex, event) => {
  selectEditingArea({
    widgetId: context.widgetId,
    sectionId: context.sectionId,
    sectionEditingScope: 'collections',
    sectionEditingArea: `collection-${datasetIndex}`,
    editingAreaMeta: JSON.stringify({
      collectionIndex: datasetIndex
    }),
    clickPosition: getClickPositionFromEvent(event.native)
  });
}; // Handles clicks in empty chart areas.


const handleEmptyAreaClick = (context, event) => {
  selectEditingArea({
    widgetId: context.widgetId,
    sectionId: context.sectionId,
    sectionEditingScope: 'chart',
    sectionEditingArea: 'chart',
    clickPosition: getClickPositionFromEvent(event.native)
  });
}; // Apply user selections of an individual
// chart slice as the active editing area


function handleDirectElementClick(_ref2, event, activeElements) {
  let {
    widgetId,
    sectionId,
    chartData
  } = _ref2;
  if (event.type !== 'click' || activeElements.length === 0) return;
  const [item] = activeElements;
  const dataset = chartData.datasets[item.datasetIndex] || {
    data: []
  };
  const selection = dataset.selections[item.index] || '';
  const selectionIndex = dataset.selectionIndexes[item.index] ?? -1;
  const collectionIndex = dataset.collectionIndex ?? 0;
  if (selectionIndex < 0) return; // Send selection

  selectEditingArea({
    widgetId,
    sectionId,
    sectionEditingScope: 'selections',
    sectionEditingArea: `item-${selectionIndex}`,
    clickPosition: getClickPositionFromEvent(event.native),
    editingAreaMeta: JSON.stringify({
      selection,
      selectionIndex,
      collectionIndex
    })
  });
} // Get the nearest dataset index to the mouse position


export function findNearestDataset(event, chart) {
  // @ts-ignore
  const canvasPosition = getRelativePosition(event, chart);
  const clickX = canvasPosition.x;
  const clickY = canvasPosition.y;
  let minDistance = Infinity;
  let nearestDatasetIndex = null;
  let isAboveLine = null;

  for (let datasetIndex = 0; datasetIndex < chart.data.datasets.length; datasetIndex++) {
    const meta = chart.getDatasetMeta(datasetIndex);
    if (meta.hidden) continue;
    const points = meta.data;

    for (let i = 0; i < points.length - 1; i++) {
      const x1 = points[i].x;
      const y1 = points[i].y;
      const x2 = points[i + 1].x;
      const y2 = points[i + 1].y;
      const distance = distanceToLine(x1, y1, x2, y2, clickX, clickY);

      if (distance < minDistance) {
        minDistance = distance;
        nearestDatasetIndex = datasetIndex; // Determine if click is above or below line
        // Using the sign of the cross product to determine position

        const lineVectorX = x2 - x1;
        const lineVectorY = y2 - y1;
        const clickVectorX = clickX - x1;
        const clickVectorY = clickY - y1; // Cross product in 2D

        const crossProduct = lineVectorX * clickVectorY - lineVectorY * clickVectorX;
        isAboveLine = crossProduct < 0;
      }
    }
  }

  return {
    datasetIndex: nearestDatasetIndex,
    distance: minDistance,
    isAboveLine
  };
} // Calculate the distance from a point to a line segment

export function distanceToLine(x1, y1, x2, y2, pointX, pointY) {
  // Calculate the vector representing the line segment
  const lineVectorX = x2 - x1;
  const lineVectorY = y2 - y1;
  const lineLength = Math.sqrt(lineVectorX ** 2 + lineVectorY ** 2); // Handle degenerate case where line segment is actually a point

  if (lineLength === 0) {
    return calculateDistance(x1, y1, pointX, pointY);
  } // Calculate the relative position of the projection on the line segment
  // This will be between 0 (start point) and 1 (end point) if the projection
  // falls on the segment


  const projectionPosition = ((pointX - x1) * lineVectorX + (pointY - y1) * lineVectorY) / lineLength ** 2; // Projection falls before the start point

  if (projectionPosition < 0) {
    return calculateDistance(x1, y1, pointX, pointY);
  } // Projection falls after the end point


  if (projectionPosition > 1) {
    return calculateDistance(x2, y2, pointX, pointY);
  } // Projection falls on the line segment
  // Calculate the projection point coordinates


  const projectionX = x1 + projectionPosition * lineVectorX;
  const projectionY = y1 + projectionPosition * lineVectorY;
  return calculateDistance(projectionX, projectionY, pointX, pointY);
} // Calculate the Euclidean distance between two points.

function calculateDistance(x1, y1, x2, y2) {
  return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
}