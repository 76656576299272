import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { FilterBase, FilterValueType } from '@/utils/widget/createSelections/interfaces';
export const getSelectionConfig = selectionsStrategy => {
  const isPaired = selectionsStrategy === 'default';
  return {
    base: isPaired ? FilterBase.Pairs : FilterBase.Series,
    valueType: FilterValueType.Numbers,
    seriesMinimumLength: 1
  };
}; // Temporary overrides for bar chart selections

export function overrideConfig(config) {
  return _objectSpread(_objectSpread({}, config), {}, {
    selectionsLabeled: false,
    selectionsStrategy: 'stacked'
  });
}