import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { merge, camelCase } from 'lodash';
import { useMemo } from 'react';
import { ThemeId } from '@/interfaces/theme';
import design, { remToPx } from '@/config/design';
import { deleteNotTruthey, deleteUndefinedValues } from '@/utils/objects';
import { useTheme } from '@/components/widget/Layout/ThemeProvider';
const defaultStyles = Object.freeze({
  lineWidth: 2,
  lineDash: [0, 0],
  pointRadius: 3,
  chartDataLabelColor: design.colors.gray100,
  chartAxisGridColor: design.colors.gray20,
  chartAxisFontColor: design.colors.gray60,
  chartBackgroundColor: design.colors.white,
  chartDataLabelBorderRadius: 4,
  chartDataLabelBackgroundColor: design.colors.gray10
});
const shadcnDefaultStyles = {
  pointRadius: 0,
  chartAxisTicksVisible: false,
  areaDefaultFill: 'transparent',
  chartAngleLineColor: 'transparent'
};
const styleOverridesByTheme = Object.freeze({
  [ThemeId.UniversalDesignLight]: {
    areaDefaultFill: 'fade',
    chartGradientStartOpacity: 0.1,
    chartGradientStop: 0,
    chartGradientStopOpacity: 0
  },
  [ThemeId.UniversalDesignDark]: {
    areaDefaultFill: 'fade',
    chartGradientStartOpacity: 0.2,
    chartGradientStop: 0,
    chartGradientStopOpacity: 0.1
  },
  [ThemeId.ShadcnLight]: shadcnDefaultStyles,
  [ThemeId.ShadcnDark]: shadcnDefaultStyles
});
export const getThemeDefaults = params => {
  return merge({}, defaultStyles, params.theme ? styleOverridesByTheme[params.theme] : {}, mapThemeStylesToSectionStyles(params.styles || {}));
};

const mapConfigToSectionStyles = config => {
  var _config$globalSeriesO, _config$globalSeriesO2, _config$globalSeriesO3, _config$globalSeriesO4, _config$globalSeriesO5;

  return deleteNotTruthey({
    chartBackgroundColor: config.chartBackground,
    pointRadius: (_config$globalSeriesO = config.globalSeriesOptions) === null || _config$globalSeriesO === void 0 ? void 0 : _config$globalSeriesO.pointRadius,
    lineDash: (_config$globalSeriesO2 = config.globalSeriesOptions) === null || _config$globalSeriesO2 === void 0 ? void 0 : _config$globalSeriesO2.lineDash,
    lineWidth: (_config$globalSeriesO3 = config.globalSeriesOptions) === null || _config$globalSeriesO3 === void 0 ? void 0 : _config$globalSeriesO3.lineWidth,
    lineColor: (_config$globalSeriesO4 = config.globalSeriesOptions) === null || _config$globalSeriesO4 === void 0 ? void 0 : _config$globalSeriesO4.lineColor,
    lineBackgroundColor: (_config$globalSeriesO5 = config.globalSeriesOptions) === null || _config$globalSeriesO5 === void 0 ? void 0 : _config$globalSeriesO5.lineBackgroundColor
  });
};

const mapThemeStylesToSectionStyles = styles => {
  // Convert the theme style keys to camelCase
  const themeStyles = Object.keys(styles).reduce((acc, key) => {
    acc[camelCase(key)] = styles[key];
    return acc;
  }, {});
  const bodyFontSize = remToPx(themeStyles.bodyFontSize);
  const chartAxisFontSize = remToPx(themeStyles.chartAxisFontSize);
  return deleteUndefinedValues(_objectSpread(_objectSpread({}, themeStyles), {}, {
    chartBackgroundColor: styles['body-background-color'],
    chartAxisGridColor: themeStyles.borderColor,
    chartAxisFontSize: !isNaN(chartAxisFontSize) ? chartAxisFontSize : undefined,
    chartDataLabelBackgroundColor: themeStyles.secondaryColor,
    chartDataLabelColor: themeStyles.bodyFontColor,
    chartDataLabelBorderRadius: themeStyles.radiusBase,
    legendFontFamily: themeStyles.bodyFontFamily,
    legendFontSize: !isNaN(bodyFontSize) ? bodyFontSize : undefined,
    legendLineHeight: themeStyles.bodyLineHeight,
    legendColor: themeStyles.bodyFontColor,
    titleFontFamily: themeStyles.bodyFontFamily,
    titleFontSize: !isNaN(bodyFontSize) ? bodyFontSize : undefined,
    titleLineHeight: themeStyles.bodyLineHeight,
    titleColor: themeStyles.mutedFontColor,
    lineWidth: themeStyles.chartLineWidth
  }));
};

export const useRadarStyles = config => {
  const themeCtx = useTheme();
  const defaultStyles = useMemo(() => getThemeDefaults({
    theme: themeCtx.theme.id,
    styles: themeCtx.styles
  }), [themeCtx]); // The merge helper mutates the first object,
  // so we use an empty object to keep the default styles safe.
  // Merge styles in order: Default -> Defaults by Theme -> Theme Styles -> Config

  const themeStyles = useMemo(() => merge({}, defaultStyles, mapConfigToSectionStyles(config)), [config, defaultStyles]);
  return {
    themeStyles,
    defaultStyles,
    themeCtx
  };
};