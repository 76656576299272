// Disallowed characters for number input
// e, E - scientific notation
const DISABLED_NUMBER_INPUT_CHARACTERS = ['e', 'E', '-', '+', '.', ',']; // Check if the key is a valid number input key

export const isValidNumberInputKey = key => {
  return !DISABLED_NUMBER_INPUT_CHARACTERS.includes(key);
}; // Convert string to integer and
// remove all non-numeric characters

export const strToInteger = str => {
  const result = parseInt(str.replace(/[^0-9-]/g, ''), 10);
  return isNaN(result) ? -1 : result;
}; // Convert string to number and
// remove all non-numeric characters

export const stringToNumber = function (str) {
  let nanValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  const result = parseFloat(str.replace(/[^0-9.-]/g, '').split('.').slice(0, 2).join('.'));
  return isNaN(result) ? nanValue : result;
}; // Determine if a value is a finite number

export const isNumeric = value => {
  return /^-?\d+(\.\d+)?$/.test(value.trim());
}; // Determine if a value is a valid number

export const isValidNumber = value => {
  return typeof value === 'number' && !isNaN(value);
}; // Returns the next index

export function createNextIndexGenerator() {
  let index = 0;
  return () => index++;
} // Returns rounded integer value

export const round = value => {
  return parseInt(value.toFixed(0), 10);
};