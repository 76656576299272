import { FilterBase } from '@/utils/widget/createSelections/interfaces';
export default {
  styles: {}
};
export const selectionConfig = {
  base: FilterBase.Series,
  seriesMinimumLength: 1
};
export const labelsSelectionConfig = {
  base: FilterBase.Pairs
};