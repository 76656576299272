import templateEngine from '@/utils/templateEngine';
import { getColorAtPalletIndex } from '@/utils/colors';
import { appendTrendlineData } from '@/components/widget/utils/trendlines';
import { getDataLabelOptions } from '../../internal/utils';
import { DEFAULT_DATA_LABEL_OPTIONS } from '../../internal/BarChart/utils'; // Create series data from group

export function createSeriesData(params) {
  const {
    selectionIndex,
    collectionIndex,
    group,
    options,
    collectionOption = {},
    colorPallet = [],
    trendlines,
    globalTrendline,
    isMultiSeries = false,
    getNextColorIndex,
    selectionLabels = [],
    getNextLabelIndex
  } = params;
  const userLabels = options.labels || {};
  const collapsible = Boolean(options.collapsible);
  const hideLegend = typeof options.omitFromLegend === 'boolean' ? options.omitFromLegend : false; // Provide default template

  const template = options.template || collectionOption.template || `{{value}}`; // Convert each item in group
  // into a renderable item for
  // the visualization

  return group.data // Filter out individual falsey items
  .filter(item => collapsible ? Boolean(item.value) : true).map((item, itemIdx) => {
    var _options$trendline, _collectionOption$tre;

    let value = parseFloat(`${item.value}`);
    value = isNaN(value) ? 0 : value;
    const selectionLabel = typeof getNextLabelIndex === 'function' ? selectionLabels[getNextLabelIndex()] : '';
    const customLabel = userLabels[item.labelCell || ''] || options.label || selectionLabel || ''; // For multi-series, the color should be based on the item index
    // to ensure each series has a different color.

    const colorIndex = isMultiSeries ? itemIdx : selectionIndex;
    const defaultColor = typeof getNextColorIndex === 'function' ? getColorAtPalletIndex(getNextColorIndex(), colorPallet) : getColorAtPalletIndex(colorIndex, colorPallet);
    const color = options.color ?? collectionOption.color ?? defaultColor;
    const seriesData = {
      value,
      selection: group.selections,
      selectionIndex,
      collectionIndex: collectionIndex ?? 0,
      hideLegend,
      name: customLabel || item.label || group.selections,
      template: templateEngine(template, {
        value: item.value
      }),
      color: color,
      borderColor: options.barBorderColor ?? color,
      borderWidth: options.barBorderWidth ?? 0,
      datalabels: getDataLabelOptions({
        value,
        backgroundColor: color,
        options,
        collectionOption,
        defaults: DEFAULT_DATA_LABEL_OPTIONS
      })
    }; // Append data to any configured trendline
    // options to be appended to chart data below

    const dataPoint = {
      x: value,
      y: value
    };

    if ((_options$trendline = options.trendline) !== null && _options$trendline !== void 0 && _options$trendline.display) {
      appendTrendlineData(trendlines, options.trendline, [dataPoint]);
    }

    if ((_collectionOption$tre = collectionOption.trendline) !== null && _collectionOption$tre !== void 0 && _collectionOption$tre.display) {
      appendTrendlineData(trendlines, collectionOption.trendline, [dataPoint]);
    }

    if (globalTrendline) {
      appendTrendlineData(trendlines, globalTrendline, [dataPoint]);
    }

    return seriesData;
  });
}