import { merge, cloneDeep, flatten } from 'lodash';
import templateEngine from '@/utils/templateEngine';
import { createNextIndexGenerator } from '@/utils/numbers';
import { getColorAtPalletIndex } from '@/utils/colors';
import toPercent from '@/utils/toPercent';
import { getDataLabelOptions } from '../internal/utils';
import { DEFAULT_DATA_LABEL_OPTIONS } from './utils';
export default function usePieDoughnutData(_ref) {
  let {
    results,
    seriesOptions,
    globalSeriesOptions = {},
    collectionOptions = [],
    selectionLabels = [],
    legends = [],
    colorPallet,
    // selectionsStrategy,
    themeStyles
  } = _ref;

  if (!results || !results.length) {
    return {
      chartData: {
        labels: [],
        datasets: []
      },
      legendData: []
    };
  } // Get totals for each collection group


  const totals = results.map(collection => {
    return collection.reduce((acc, group) => {
      group.data.forEach(item => {
        acc += parseFloat(`${item.value}`);
      });
      return acc;
    }, 0);
  });
  const datasets = results.map((collection, collectionIndex) => {
    const getNextLabelIndex = createNextIndexGenerator(); // Use the next index generator for the color index
    // to ensure each slice has a different color.

    const getNextCollectionColorIndex = createNextIndexGenerator();
    const collectionOpt = collectionOptions[collectionIndex] || {};
    const collectionTotal = totals[collectionIndex]; // Pair selection group with series options

    return collection.map(selectionResultGroup => {
      const {
        selectionIndex
      } = selectionResultGroup;
      const options = merge({}, cloneDeep(globalSeriesOptions), cloneDeep(collectionOpt), seriesOptions[selectionIndex] || {});
      return {
        group: selectionResultGroup,
        options,
        selectionIndex
      };
    }) // Filter collapsed
    .filter(_ref2 => {
      let {
        group,
        options = {}
      } = _ref2;

      // Filter out collapsed group
      // when all its' values are not truthy
      if (options.collapsible) {
        return group.data.some(item => Boolean(item.value));
      }

      return true;
    }) // Create series data
    .map(_ref3 => {
      let {
        group,
        options,
        selectionIndex
      } = _ref3;
      const userLabels = options.labels || {};
      const collapsible = Boolean(options.collapsible);
      const hideLegend = typeof options.omitFromLegend === 'boolean' ? options.omitFromLegend : false; // Provide default template

      const template = options.template || `{{value}}`; // Convert each item in group
      // into a renderable item for
      // the visualization

      return group.data // Filter out individual falsey items
      .filter(item => collapsible ? Boolean(item.value) : true).map(item => {
        const value = parseFloat(`${item.value}`);
        const labelIndex = getNextLabelIndex();
        const customLabel = userLabels[item.labelCell || ''] || options.label || (legends === null || legends === void 0 ? void 0 : legends[labelIndex]) || (selectionLabels === null || selectionLabels === void 0 ? void 0 : selectionLabels[labelIndex]) || ''; // For pairs and when selections v2 enabled,
        // the color should be based on the item index
        // to ensure each slice has a different color.

        const defaultColorIndex = getNextCollectionColorIndex(); // NOTE: strategy coloring removed in #935
        // pie/donut charts should be multi-colored by default
        // Should support colorization strategy via: https://app.clickup.com/t/86dvwf9hw
        // selectionsStrategy === 'rings'
        //   ? selectionIndex
        //   : getNextCollectionColorIndex();

        const color = options.color || getColorAtPalletIndex(defaultColorIndex, colorPallet);
        const seriesData = {
          value,
          selection: group.selections,
          selectionIndex,
          hideLegend,
          name: customLabel || item.label || group.selections,
          template: templateEngine(template, {
            value: item.value
          }),
          color: color,
          maximum: collectionTotal || 1,
          datalabels: getDataLabelOptions({
            value,
            backgroundColor: color,
            defaultColor: themeStyles.chartDataLabelColor,
            options,
            defaults: DEFAULT_DATA_LABEL_OPTIONS
          }),
          // Used for bars legend
          percent: `${toPercent(value / Math.max(1, collectionTotal), 1)}%`
        };
        return seriesData;
      });
    }) // Flatten selection groups into single
    // list for each collection of selections
    .reduce((acc, groupedData) => {
      acc.push(...groupedData);
      return acc;
    }, []).sort((a, b) => parseFloat(`${b.value}`) - parseFloat(`${a.value}`)) // sort decending
    ;
  });
  const chartData = {
    labels: datasets.reduce((acc, group) => {
      // NOTE: label text must be a string
      acc.push(...group.map(_ref4 => {
        let {
          name
        } = _ref4;
        return `${name}`;
      }).filter(name => acc.indexOf(name) === -1));
      return acc;
    }, []),
    datasets: datasets.map((group, groupIndex) => {
      const collectionOption = collectionOptions[groupIndex] || {};
      const weight = typeof collectionOption.weight === 'number' ? collectionOption.weight : 1;
      return {
        data: group.map(_ref5 => {
          let {
            value,
            selection,
            selectionIndex
          } = _ref5;
          return {
            value,
            selection,
            selectionIndex
          };
        }),
        backgroundColor: group.map(_ref6 => {
          let {
            color
          } = _ref6;
          return color;
        }),
        // hoverBackgroundColor: [],
        templates: group.map(_ref7 => {
          let {
            template
          } = _ref7;
          return template;
        }),
        titles: group.map(_ref8 => {
          let {
            name
          } = _ref8;
          return name;
        }),
        datalabels: group.map(_ref9 => {
          let {
            datalabels
          } = _ref9;
          return datalabels;
        }),
        weight
      };
    })
  };
  return {
    chartData,
    legendData: flatten(datasets).filter(_ref10 => {
      let {
        hideLegend
      } = _ref10;
      return hideLegend === false;
    })
  };
}